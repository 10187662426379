/* eslint-disable no-undef */
import React, { useRef, useMemo, ChangeEvent, FocusEvent } from "react";
import st from "../styles/items/Input.module.scss";
import { ClickCopy } from "@components/click-copy";
import usePopup from "../hooks/usePopup";
import ToolTip from "./ToolTip";

type InputItemProps = {
  name: string;
  value: string | number;
  type?: "text" | "number" | "file";
  label?: string | boolean;
  dispatch?: React.Dispatch<any>;
  method?: (value: any, param?: any) => void;
  step?: number;
  min?: number;
  eMethod?: (value: string, param?: any) => void;
  param?: any;
  inputType?: string;
  dispatchType?: string;
  size?: number;
  errors?: Record<string, string[]>;
  error?: boolean;
  errorMsg?: string;
  inputSetup?: any;
  rate?: Record<string, number>;
  wallet?: any;
  readOnly?: boolean;
  classType?: string[];
  inlineButtons?: React.ReactNode;
  inlineError?: boolean;
  convertValues?: boolean;
  specialBetSetter?: boolean;
  icon?: React.ReactNode;
  IconItem?: React.ReactNode;
  iconPosition?: string;
  placeholder?: string;
  valueIconCode?: string;
  copy?: boolean;
  onFocus?: (param: any) => void;
  toolTip?: {
    toolTipMethod: () => void;
    [key: string]: any;
  };
  style?: React.CSSProperties;
  labelClass?: string;
  outerRef?: React.RefObject<HTMLInputElement>;
  pointerCursor?: boolean;
  isFileSelector?: boolean;
  extraHeaderContent?: React.ReactNode;
  autoFocus?: boolean;
};

const InputItem: React.FC<InputItemProps> = (props) => {
  const {
    name,
    value,
    type = "text",
    label = false,
    dispatch,
    method,
    step,
    min,
    eMethod,
    param,
    inputType,
    dispatchType = "FIELD",
    size = 100,
    errors = false,
    error = false,
    errorMsg = "Custom Error",
    inputSetup = false,
    rate,
    wallet = false,
    readOnly = false,
    classType = [],
    inlineButtons = false,
    inlineError = false,
    convertValues = false,
    specialBetSetter = false,
    icon = false,
    IconItem = false,
    iconPosition = false,
    placeholder = "",
    valueIconCode = false,
    copy = false,
    onFocus = false,
    toolTip = false,
    style = false,
    labelClass = "",
    outerRef = null,
    pointerCursor = false,
    isFileSelector = false,
    extraHeaderContent = false,
    autoFocus = false,
  } = props;

  const setMessage = usePopup();
  const inputObj = useMemo(
    () => ({
      id: name,
      name,
      value: value,
      type,
      maxLength: size,
      placeholder,
      autoFocus,
    }),
    [name, value, type, size, placeholder, autoFocus]
  ) as any;

  const timerEnabled = errors ? true : false;
  const timer = useRef<NodeJS.Timeout | null>(null);

  const initializeTimer = (e: ChangeEvent<HTMLInputElement>) => {
    return setTimeout(() => {
      dispatch?.({ type: "ERRORCHECK", target: name, payload: e.target.value });
    }, 1000);
  };

  if (dispatch) {
    inputObj.onChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (timerEnabled && timer.current) {
        clearTimeout(timer.current);
      }
      if (timerEnabled) {
        timer.current = initializeTimer(e);
      }
      dispatch({ type: dispatchType, target: name, payload: e.target.value });
    };
  }

  if (method) {
    inputObj.onChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (type === "number") {
        method(e.target.value, param);
      } else if (type === "file" && isFileSelector) {
        method(e.target.files ? e.target.files[0] : null, param);
      } else {
        method(e.target.value, param);
      }
    };
  }

  if (eMethod) {
    if (type === "number") {
      inputObj.onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const keyCode = e.keyCode || e.which;
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
          e.preventDefault();
        }
        if (keyCode === 46 && e.currentTarget.value.includes(".")) {
          e.preventDefault();
        }
      };

      inputObj.onChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        const length = value.split(".")[1]?.length || 0;
        if (length > 2) {
          value = value.slice(0, -1);
        }
        eMethod(value, param);
      };
    } else {
      inputObj.onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        eMethod(value, param);
      };
    }
  }

  if (onFocus) {
    inputObj.onFocus = (e: FocusEvent<HTMLInputElement>) => {
      onFocus(param);
    };
  }

  const classes = `${classType.map((item) => st[item]).join(" ")} ${st["reset-file-input"]} ${pointerCursor ? st["cursor-pointer"] : ""}`;

  const renderInputDisplay = () => {
    if (inlineButtons || inputSetup === "inlineContent" || copy) {
      return (
        <div
          className={`${st["inline-btn-container"]} ${inlineError ? st["inline-error"] : ""}`}
        >
          {valueIconCode && (
            <div className={st["inline-value-icon"]}>{valueIconCode}</div>
          )}
          <input
            id={name}
            className={`${classType.map((item) => st[item]).join(" ")} ${wallet ? st["icon-space"] : ""}`}
            {...inputObj}
            {...(toolTip ? { onBlur: toolTip.toolTipMethod } : {})}
          />
          <div className={st["btn-container"]}>
            {inlineButtons}
            {copy && value !== null && (
              <ClickCopy stringToCopy={value as string} />
            )}
          </div>
        </div>
      );
    } else if (readOnly) {
      return (
        <input
          value={value}
          className={st["read-only"]}
          readOnly
          autoComplete="off"
          {...inputObj}
          required
        />
      );
    } else if (toolTip) {
      return (
        <>
          {icon && <div className={st["icon"]}>{icon}</div>}
          <ToolTip {...(toolTip as any)}>
            <input className={classes} {...inputObj} required />
          </ToolTip>
        </>
      );
    } else {
      return (
        <>
          {icon && <div className={st["icon"]}>{icon}</div>}
          <input className={classes} {...inputObj} required ref={outerRef} />
        </>
      );
    }
  };

  return (
    <div
      className={`${label ? st["icon-padding"] : ""} ${st.inputWrapper} ${classes} ${(errors && errors[name]?.length) || error ? st["error"] : ""}`}
    >
      {label && (
        <div className={`${st["label-heading"]} ${st["labelClass"]}`}>
          <label htmlFor={name} className={st[labelClass]}>
            {label}
          </label>
        </div>
      )}
      {extraHeaderContent}
      {IconItem && IconItem}
      {renderInputDisplay()}
    </div>
  );
};

export default InputItem;
