import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@lib";

const buttonVariants = cva(
  "inline-flex items-center justify-center gap-2 border-2 border-solid whitespace-nowrap rounded-md text-[13px] transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-[0.333] [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 transition-all duration-300 ease-in-out",
  {
    variants: {
      variant: {
        blue: "bg-blue border-blue-highlight hover:drop-shadow-[0_0_10px_rgba(0,56,255,0.25)]",
        green:
          "bg-secondary-fill-color border-secondary-stroke-color hover:shadow-green",
        normal:
          "border border-solid border-white/5 bg-white/5 text-[rgba(232,229,255,0.75)]",
        success:
          "border border-solid border-[rgba(100,180,37,0.15)] bg-gradient-to-b from-[rgba(22,217,76,0.15)] to-[rgba(16,162,57,0.15)] text-[#64b425] [&_svg]:fill-[#64b425] [&_path]:fill-[#64b425]",
        loading:
          "bg-gradient-to-b from-[#9CA2C4]/5 to-[#5E679E]/5 border-white/5",
      },
      size: {
        default: "min-h-[50px] px-10 py-2",
        sm: "h-[33px] rounded-md px-[10px] text-xs [&_svg]:w-[12px]",
      },
    },
    defaultVariants: {
      variant: "blue",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
