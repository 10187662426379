// Imported modules
import { useState, useRef, useEffect } from "react";
const allowedClasses = [
  'popup-class',
  'moonpay-widget__close-overlay-button-icon',
  'moonpay-widget__iframe-container'
];
/**
 * Custom React Hook to detect clicks outside of the component.
 * Also detects the 'Escape' key press to hide the component.
 *
 * @param {boolean} initialIsVisible - The initial visibility state of the component
 * @param {Function} method - The callback to be invoked when the component is set to not visible
 * @param {Object} safeClickRef - The ref(s) of the area(s) which won't cause the component to hide when clicked
 * @returns {Object} - The ref to attach to the component, the visibility state and its setter function
 */
export default function useDetectOutside(initialIsVisible, method, safeClickRef) {
  // Component visibility state
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  
  // Ref for the component
  const ref = useRef(null);

  // Handler for hiding the component
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
      if(method) {
        method();
      }
    }
  };

  // Handler for outside clicks
  const handleClickOutside = event => {
    // Only continue if the click was outside of the component
    if (ref.current && !ref.current.contains(event.target)) {
      let safe = false;

      // Check if the click was inside a safe area
      if (Array.isArray(safeClickRef)) {
        safeClickRef.forEach(refItem => {
          if (refItem && refItem.current.contains(event.target)) {
            safe = true;
          }
        });
      } else if (safeClickRef && safeClickRef.current.contains(event.target)) {
        safe = true;
      }

      // Check if the click was on an element with the class 'popup-class'
      const targetClassList = event.target.classList;
   

      let classCheck = allowedClasses.some(className => targetClassList.contains(className));
      
      if (classCheck) {
        safe = true;
      }

      //Dropdown wont trigger close if clicked on the dropdown
      if(event.target.closest('.drop-down-class')) {
        safe = true;
      }
      

      // If the click was not safe, hide the component and call the callback
      if (!safe) {
        setIsComponentVisible(false);
        if (method) {
          method();
        }
      }
    }
  };

  // Add event listeners for 'keydown' and 'mousedown' events
  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("mousedown", handleClickOutside, true);

    // Cleanup function to remove the listeners when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}
